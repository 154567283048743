/*variables*/
:root{

  --brownHue: 35;
  --brown: #895710;
  --brown1: hsl(var(--brownHue), 100%, 50%);
  --brown2: hsl(var(--brownHue), 80%, 50%);
  --brown3: hsl(var(--brownHue), 60%, 50%);
  --brown4: hsl(var(--brownHue), 40%, 50%);
  --brown5: hsl(var(--brownHue), 20%, 50%);
  --brownLight1: hsl(var(--brownHue), 100%, 70%);
  --brownLight2: hsl(var(--brownHue), 80%, 70%);
  --brownLight3: hsl(var(--brownHue), 60%, 70%);
  --brownLight4: hsl(var(--brownHue), 40%, 70%);
  --brownLight5: hsl(var(--brownHue), 20%, 70%);
  --brownLighter1: hsl(var(--brownHue), 100%, 90%);
  --brownLighter2: hsl(var(--brownHue), 80%, 90%);
  --brownLighter3: hsl(var(--brownHue), 60%, 90%);
  --brownLighter4: hsl(var(--brownHue), 40%, 90%);
  --brownLighter5: hsl(var(--brownHue), 20%, 90%);
  --brownDark1: hsl(var(--brownHue), 100%, 30%);
  --brownDark2: hsl(var(--brownHue), 80%, 30%);
  --brownDark3: hsl(var(--brownHue), 60%, 30%);
  --brownDark4: hsl(var(--brownHue), 40%, 30%);
  --brownDark5: hsl(var(--brownHue), 20%, 30%);
  --brownDarker1: hsl(var(--brownHue), 100%, 10%);
  --brownDarker2: hsl(var(--brownHue), 80%, 10%);
  --brownDarker3: hsl(var(--brownHue), 60%, 10%);
  --brownDarker4: hsl(var(--brownHue), 40%, 10%);
  --brownDarker5: hsl(var(--brownHue), 20%, 10%);

  --yellowHue: 44;
  --yellow: #f0cf75;
  --yellow1: hsl(var(--yellowHue), 100%, 50%);
  --yellow2: hsl(var(--yellowHue), 80%, 50%);
  --yellow3: hsl(var(--yellowHue), 60%, 50%);
  --yellow4: hsl(var(--yellowHue), 40%, 50%);
  --yellow5: hsl(var(--yellowHue), 20%, 50%);
  --yellowLight1: hsl(var(--yellowHue), 100%, 70%);
  --yellowLight2: hsl(var(--yellowHue), 80%, 70%);
  --yellowLight3: hsl(var(--yellowHue), 60%, 70%);
  --yellowLight4: hsl(var(--yellowHue), 40%, 70%);
  --yellowLight5: hsl(var(--yellowHue), 20%, 70%);
  --yellowLighter1: hsl(var(--yellowHue), 100%, 90%);
  --yellowLighter2: hsl(var(--yellowHue), 80%, 90%);
  --yellowLighter3: hsl(var(--yellowHue), 60%, 90%);
  --yellowLighter4: hsl(var(--yellowHue), 40%, 90%);
  --yellowLighter5: hsl(var(--yellowHue), 20%, 90%);
  --yellowDark1: hsl(var(--yellowHue), 100%, 30%);
  --yellowDark2: hsl(var(--yellowHue), 80%, 30%);
  --yellowDark3: hsl(var(--yellowHue), 60%, 30%);
  --yellowDark4: hsl(var(--yellowHue), 40%, 30%);
  --yellowDark5: hsl(var(--yellowHue), 20%, 30%);
  --yellowDarker1: hsl(var(--yellowHue), 100%, 5%);
  --yellowDarker2: hsl(var(--yellowHue), 80%, 5%);
  --yellowDarker3: hsl(var(--yellowHue), 60%, 5%);
  --yellowDarker4: hsl(var(--yellowHue), 40%, 5%);
  --yellowDarker5: hsl(var(--yellowHue), 20%, 5%);

  --brownHsl: 35, 79%, 30%;
  --brownHsl1: var(--brownHue), 100%, 50%;
  --brownHsl2: var(--brownHue), 80%, 50%;
  --brownHsl3: var(--brownHue), 60%, 50%;
  --brownHsl4: var(--brownHue), 40%, 50%;
  --brownHsl5: var(--brownHue), 20%, 50%;
  --brownHslLight1: var(--brownHue), 100%, 70%;
  --brownHslLight2: var(--brownHue), 80%, 70%;
  --brownHslLight3: var(--brownHue), 60%, 70%;
  --brownHslLight4: var(--brownHue), 40%, 70%;
  --brownHslLight5: var(--brownHue), 20%, 70%;
  --brownHslLighter1: var(--brownHue), 100%, 90%;
  --brownHslLighter2: var(--brownHue), 80%, 90%;
  --brownHslLighter3: var(--brownHue), 60%, 90%;
  --brownHslLighter4: var(--brownHue), 40%, 90%;
  --brownHslLighter5: var(--brownHue), 20%, 90%;
  --brownHslDark1: var(--brownHue), 100%, 30%;
  --brownHslDark2: var(--brownHue), 80%, 30%;
  --brownHslDark3: var(--brownHue), 60%, 30%;
  --brownHslDark4: var(--brownHue), 40%, 30%;
  --brownHslDark5: var(--brownHue), 20%, 30%;
  --brownHslDarker1: var(--brownHue), 100%, 10%;
  --brownHslDarker2: var(--brownHue), 80%, 10%;
  --brownHslDarker3: var(--brownHue), 60%, 10%;
  --brownHslDarker4: var(--brownHue), 40%, 10%;
  --brownHslDarker5: var(--brownHue), 20%, 10%;

  --yellowHsl: 44, 80%, 70%;
  --yellowHsl1: var(--yellowHue), 100%, 50%;
  --yellowHsl2: var(--yellowHue), 80%, 50%;
  --yellowHsl3: var(--yellowHue), 60%, 50%;
  --yellowHsl4: var(--yellowHue), 40%, 50%;
  --yellowHsl5: var(--yellowHue), 20%, 50%;
  --yellowHslLight1: var(--yellowHue), 100%, 70%;
  --yellowHslLight2: var(--yellowHue), 80%, 70%;
  --yellowHslLight3: var(--yellowHue), 60%, 70%;
  --yellowHslLight4: var(--yellowHue), 40%, 70%;
  --yellowHslLight5: var(--yellowHue), 20%, 70%;
  --yellowHslLighter1: var(--yellowHue), 100%, 90%;
  --yellowHslLighter2: var(--yellowHue), 80%, 90%;
  --yellowHslLighter3: var(--yellowHue), 60%, 90%;
  --yellowHslLighter4: var(--yellowHue), 40%, 90%;
  --yellowHslLighter5: var(--yellowHue), 20%, 90%;
  --yellowHslDark1: var(--yellowHue), 100%, 30%;
  --yellowHslDark2: var(--yellowHue), 80%, 30%;
  --yellowHslDark3: var(--yellowHue), 60%, 30%;
  --yellowHslDark4: var(--yellowHue), 40%, 30%;
  --yellowHslDark5: var(--yellowHue), 20%, 30%;
  --yellowHslDarker1: var(--yellowHue), 100%, 5%;
  --yellowHslDarker2: var(--yellowHue), 80%, 5%;
  --yellowHslDarker3: var(--yellowHue), 60%, 5%;
  --yellowHslDarker4: var(--yellowHue), 40%, 5%;
  --yellowHslDarker5: var(--yellowHue), 20%, 5%;


  --black: #000000;
  --white: #eeeeee;

  --gray1: hsl(0, 0%, 90%);
  --gray2: hsl(0, 0%, 70%);
  --gray3: hsl(0, 0%, 50%);
  --gray4: hsl(0, 0%, 30%);
  --gray5: hsl(0, 0%, 10%);


  --dulledImage: grayscale(0.2) sepia(0.4);
  --normalImage: grayscale(0) sepia(0);


  --valid: #14b830;
  --invalid: #e61919;
  --warn: #edc645;

  --sectionInnerMaxWidth: 1200px;

  --elevation0: none;
  --elevation1: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  --elevation2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  --elevation3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  --elevation4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --elevation5: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --elevation6: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --elevation7: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  --elevation8: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  --elevation9: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  --spacing1: 0.146rem;
  --spacing2: 0.236rem;
  --spacing3: 0.382rem;
  --spacing4: 0.618rem;
  --spacing5: 1rem;
  --spacing6: 1.618rem;
  --spacing7: 2.618rem;
  --spacing8: 4.235rem;
  --spacing9: 6.854rem;
  --spacing10: 11.089rem;
  --spacing11: 17.942rem;
  --spacing12: 29.03rem;

  --fontSize0: 0.625rem;
  --fontSize1: 0.8125rem;
  --fontSize2: 1rem;
  --fontSize3: 1.25rem;
  --fontSize4: 1.625rem;
  --fontSize5: 2.0625rem;
  --fontSize6: 2.625rem;
  --fontSize7: 4.226rem;
  --fontSize8: 6.804rem;
  --fontSize9: 10.95rem;


  --primaryFont: "Raleway", sans-serif;
  --secondaryFont: "Cinzel", serif;

  --curve: 0;

  --copyMaxWidth: 600px;
}
