@import "./_fonts.scss";
@import "./_variables.scss";
@import "./_headers.scss";
@import "./_article.scss";
@import "./_section.scss";
@import "./_mixins.scss";


/*general*/
html{
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
body{
  position: relative;

  color: var(--white);
  font-family: var(--primaryFont);

  background: var(--black);
  &.noscroll{
    max-height: 100vh;
    overflow: hidden;
    touch-action: none;

  }
}

body,
html{
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  font-size: 16px;

  font-family: var(--primaryFont);
}

.invis{
  opacity: 0;
}
.initialInvis{
  visibility: hidden;
}
.hidden{
  visibility: hidden;
}

.d-none{
  display: none;
}

p{
  line-height: 1.6em;
}

main{
  position: relative;

  min-height: calc(100vh - 445px); //445px = height of the navbar and the footer for wide screen
}


a:not(.custom){
  color: var(--yellow);
  font-weight: 700;

  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

  svg path{
    transition: fill 0.1s ease-in-out;
  }

  &:hover{
    color: var(--black);

    background-color: var(--yellow);

    svg path{
      fill: var(--black);
    }
  }
}

::selection{
  color: var(--black);

  background-color: var(--yellowLight2);

  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}
/*width*/
::-webkit-scrollbar{
  width: 1rem;
}

/*Track*/
::-webkit-scrollbar-track{
  background: var(--brownDarker4);
}

/*Handle*/
::-webkit-scrollbar-thumb{
  background: var(--yellowDark2);
}

/*Handle on hover*/
::-webkit-scrollbar-thumb:hover{
  background: var(--yellow2);
}

*{
  -webkit-tap-highlight-color: transparent;
}

aside{
  position: relative;

  padding: var(--spacing5);

  line-height: 1.6em;

  background-color: var(--yellowDarker1);
  border-width: 1px;
  @include gold-border;
}

ol,
ul{
  line-height: 1.6em;
}
